import $ from 'jquery';
import * as THREE from 'three';

import SETTINGS from '../controllers/Settings.js';
import AppStatus from '../controllers/AppStatus.js';
import Utils from '../utils/Utils.js';
import LoaderXHR from '../loading/LoaderXHR.js';
import PageLoader from '../loading/PageLoader.js';
import Loader from '../loading/Loader.js';
import Fbo from '../utils/Fbo.js'
import RendererController from '../controllers/RendererController.js';
import MultiStorage from '../utils/MultiStorage.js';
import CameraController from '../controllers/CameraController.js'
import EmbeddedText from '../controllers/EmbeddedText.js'

import MottoView from './MottoView.js';


/*

case:
firstTask : always wait for timeout
secondTask and return, same thing : skip on android, wait for timeout on ios
chapterPage : auto activate on android, wait for click on ios, skip when done on ios

*/

var ALLOW_MODES = {
AUTO_ACTIVATE: 0,
TIMEOUT: 1,
TAP: 2
};


//------------------------
// 
//  Base class for all views
//
//-------------------------
class AllowCameraView extends MottoView {

	constructor(_info, batchName, doneCallback) {
		_info.noMenu = true;
		super(_info, batchName, doneCallback);
		this.textType = this.info["textType"] || 'top';

		// Add image to preloading queue
		this.videoWidth = 256;
		this.videoHeight = 256;
		this.lockRight = true;
		this.lockLeft = true;
		this.triedOnce = false;
		this.done = false;
		this.timeOutLaunched = false;
		this.nextCamera = null;
		this.isAllowCamera = true;
		this.mode = 0;
		this.refused = false;
		this.androidActivating = false;
		this.androidActivatingTime = 0;


		//
		// <br><br>We won’t submit your videos without permission, and no audio will ever be recorded.
		// <br><br>We won’t submit your videos without permission, and no audio will ever be recorded.
		//

		// this.info["textReload"] = this.info["textReload"]||"Sorry, you won’t be able to continue with MOTTO unless you grant it access to the camera.<br><br>We won’t submit your videos without permission, and no audio will ever be recorded.";
		this.info["refusedReload"] = EmbeddedText[SETTINGS.LANGUAGE].camera.refusedReload;//||this.info["refusedReload"]||"Sorry, you won’t be able to continue with MOTTO unless you grant it access to the camera.<br><br><a href=\"#\" id=\"page-reload\">Reload the page to continue</a>";
		this.info["refusedIos"] = EmbeddedText[SETTINGS.LANGUAGE].camera.refusedIos;//||this.info["refusedIos"]||"To continue, you need to let MOTTO access your camera.<br><br>Either you really don’t want to continue with this project or else the camera is turned off in your Safari//Chrome settings.<br><br>To fix this, go to Settings &gt; Safari//Chrome &gt; Camera &amp; Microphone Access and turn it ON.<br><br><a href=\"#\" id=\"page-reload\">Then reload the page</a>";
		this.info["refusedAndroid"] = EmbeddedText[SETTINGS.LANGUAGE].camera.refusedAndroid;//||this.info["refusedAndroid"]||"To continue, you need to let MOTTO access your camera by editing Chrome&#39;s site settings for this page. Do this by tapping the lock icon at the top of this page, choosing Site Settings, and allowing Access to Camera.<br><br><a href=\"#\" id=\"page-reload\">Then reload the page</a>";
		// this.info["refusedOthers"] = this.info["refusedOthers"]||"Sorry, you won’t be able to continue with MOTTO unless you grant it access to the camera.<br><br><a href=\"#\" id=\"page-reload\">Reload the page to continue</a>";
		this.info["refusedOthers"] = EmbeddedText[SETTINGS.LANGUAGE].camera.refusedOthers;//||this.info["refusedOthers"]||"To continue, you need to let MOTTO access your camera.<br><br>Either you really don’t want to continue with this project or else the camera is turned off in your browser's settings.<br><br>To fix this, go to Settings &gt; Camera &amp; Microphone Access and turn it ON.<br><br><a href=\"#\" id=\"page-reload\">Then reload the page</a>";
		this.info["refusedIos"] = this.info["refusedIos"].replace(/{browser}/gi, /CriOS/.test(navigator.userAgent)?'Chrome':'Safari').replace(/{browser}/gi, /CriOS/.test(navigator.userAgent)?'Chrome':'Safari');
		this.info["cameraWorking"] =  EmbeddedText[SETTINGS.LANGUAGE].camera.refusedOthers;
		// .replace(/{browser}/gi, /CriOS/.test(navigator.userAgent)?'Chrome':'Safari').replace(/{browser}/gi, /CriOS/.test(navigator.userAgent)?'Chrome':'Safari');


		this.isIOS = SETTINGS.isIOS;

	}

	//
	// When starting - decode image for rendering
	//
	start() {
		if (this.started) return;
		super.start();
		var cameraAllowedOnce = MultiStorage.getGlobalState().cameraAllowedOnce;

		if (!this.isIOS && cameraAllowedOnce && !this.triedOnce) { //&& !this.params.firstTask
		
			this.mode = ALLOW_MODES.AUTO_ACTIVATE;
			this.lockRight = true;

		//not ios, or camera allowed
		} else if ((this.isIOS||!cameraAllowedOnce) && !this.params.chapterPage) {
			
			this.mode = ALLOW_MODES.TIMEOUT;

		} else if (this.isIOS && this.params.chapterPage) {

			this.mode = ALLOW_MODES.TAP;

		}


		
		console.log("Starting Allow Camera view with mode:",this.mode);
		if (this.mode ==  ALLOW_MODES.AUTO_ACTIVATE) {

			this.triedOnce = true;
			CameraController.start(this.nextCamera);
			this.androidActivatingTime = performance.now();
			this.androidActivating = true;

			$(this.textDiv).toggleClass('respiration', true);
			$(this.textDiv).html(SETTINGS.LANGUAGE == 'fr' ? '<br>Activation de la caméra' : '<br>Activating camera');
			$(this.textDiv).removeClass(this.textType);
			$(this.textContainer).removeClass(this.textType);
			this.textType = 'center';
			$(this.textContainer).toggleClass('center', true);
			$(this.textDiv).addClass('center', true);

		}
		$(this.textDiv).toggleClass('allow-camera-text ', true);
	}

	activate() {
		if (this.active) return;
		super.activate();


		console.log("Activating Allow Camera view with mode:",this.mode);

		if (this.mode == ALLOW_MODES.TAP) {
			$(document).off('touchstart.allowreturn');
			if (!this.triedOnce) {
				$(document).on('touchstart.allowreturn', function(e) {
					this.lockRight = true;
					this.lockLeft = true;
					if (!this.triedOnce) CameraController.start(this.nextCamera);
					this.triedOnce = true;
				}.bind(this));
			}
		}

		// var firstReload = true;
		if (SETTINGS.isIOS) {
			// globalState.cameraAttempts = globalState.cameraAttempts||0;
			// globalState.cameraAttempts++;
			var isReloaded = (MultiStorage.getGlobalState().cameraAttempts||0)>0;
			// if (isReloaded && !this.info.isAutomaticReturn) $(this.textDiv).html(this.info.textReload);
			// if (isReloaded) {
			// 	window.location.reload();
			// }
		}



		var globalState = MultiStorage.getGlobalState();
		globalState.isOnAllowCamera = true;
		MultiStorage.setGlobalState(globalState);

		this.androidActivatingTime = performance.now();
		if (CameraController.cameraAllowed) {
			$(this.textDiv).toggleClass('respiration', false);
			$(this.textDiv).html(EmbeddedText[SETTINGS.LANGUAGE].camera.working);
			this.lockRight = false;
			this.lockLeft = false;
			$(this.textDiv).toggleClass('top', false);
			$(this.textDiv).toggleClass('center', true);
			this.textType = 'center';
			var globalState = MultiStorage.getGlobalState();
			globalState.cameraAttempts = 0;
			MultiStorage.setGlobalState(globalState);
		} else {
			var fontSize = 1.15;
			while ($(this.textDiv).height() + AppStatus.innerHeight()*0.07 >= AppStatus.innerHeight() * 0.3 ) {
				fontSize -= 0.05;
				$(this.textDiv).css('font-size', fontSize + 'em');
			}
		}
		

		$(this.pageCounterDiv).hide();
		this.refused = false;
	}

	refocus() {
		// if (!this.confirmed) {
		console.log("GOT REFOCUS EVENT");
		if (this.mode == ALLOW_MODES.TIMEOUT) {
			this.triedOnce = false;
			this.timeOutLaunched = false;
			if (this.positionX > -0.25 && this.positionX < 0.25 && !this.params.chapterPage) { //} && !AppStatus.goingBackwards) {
				this.timeOutLaunched = true;
				setTimeout(function(e) {
					if (!this.triedOnce) {
						console.log("launching timeout");
						CameraController.start(this.nextCamera);
						this.triedOnce = true;
					}
				}.bind(this), 250);
			}
		}
		// }
	}
	
	deactivate() {
		if (!this.active) return;
		super.deactivate();
		CameraController.stop();

		var globalState = MultiStorage.getGlobalState();
		globalState.isOnAllowCamera = false;
		MultiStorage.setGlobalState(globalState);
		$(this.textDiv).toggleClass('respiration', false);


		$(document).off('touchstart.allowreturn');
	}

	//
	// When disposing - release image
	//
	stop() {
		if (!this.started) return;
		$(this.textDiv).toggleClass('respiration', false);
		$(this.textDiv).toggleClass('allow-camera-text ', false);
		super.stop();
		$(this.textDivBottom).remove();
		this.textDivBottom = null;
		$(this.textContainer).append(this.textDiv);
		this.timeOutLaunched = false;
		$(document).off('touchstart.allowreturn');
	}

	returnFocus() {
		$(this.textDiv).toggleClass('allow-camera-text ', false);
		$(this.textDiv).toggleClass('respiration', false);
		$(this.textDiv).html(EmbeddedText[SETTINGS.LANGUAGE].views.allowCameraReturnNoWelcomeBack);
		$(this.textDiv).toggleClass('top', true);
		$(this.textDiv).toggleClass('center', false);
		
		this.triedOnce = false;
		this.timeOutLaunched = false;
		this.done = false;
		this.start();
		this.activate();
	}

	update() {
		if (!this.started) return;

		if (this.mode == ALLOW_MODES.TIMEOUT) {
			// console.log("TIMEOUT?",!this.triedOnce, !this.timeOutLaunched, this.positionX)
			if (!this.triedOnce && !this.timeOutLaunched && this.positionX > -0.25 && this.positionX < 0.25) { //} && !AppStatus.goingBackwards) {
				this.timeOutLaunched = true;
				console.log("LAUNCH TIMEOUT");
				setTimeout(function(e) {
					if (!this.triedOnce) {
						console.log("try from timeout!");
						CameraController.start(this.nextCamera);
						this.triedOnce = true;
					}
				}.bind(this), 250);
			}
		}

		super.update();

		//this.triedOnce && 
		var cameraAllowedDone = CameraController.cameraAllowed;
		if (performance.now() - this.androidActivatingTime < 2000) cameraAllowedDone = false; 

		if (cameraAllowedDone && !this.done) {//} || window.CameraController.cameraRefused)) {
			this.done = true;

			var globalState = MultiStorage.getGlobalState();
			// if (this.doneCallback && globalState.cameraAttempts<2 && !SETTINGS.isIOS) {
				
				setTimeout(function() {
					$(this.textDiv).toggleClass('respiration', false);
					$(this.textDiv).toggleClass('allow-camera-text ', false);
					$(this.textDiv).html(EmbeddedText[SETTINGS.LANGUAGE].camera.working);
					$(this.textDiv).toggleClass('top', false);
					$(this.textDiv).toggleClass('center', true);
				}.bind(this),50);
				
				AppStatus.frameNeedsUpdate = true;

			// } else {
				// console.log("Done",this.doneCallback, (globalState.cameraAttempts||0)<2);
				if (this.doneCallback && (globalState.cameraAttempts||0)<2) this.doneCallback(this);
			// }

			this.lockRight = false;
			this.lockLeft = false;
			this.doneCallback = null;
			globalState.cameraAttempts = 0;
			MultiStorage.setGlobalState(globalState);
		}	

		if (!this.refused && CameraController.cameraRefused) {
			console.log("REFUSEDDDD!!!",MultiStorage.getGlobalState().cameraAttempts);
			AppStatus.frameNeedsUpdate = true;
			this.backgroundColor = 0x000000;
			$(this.textDiv).toggleClass('respiration', false);
			$(this.textDiv).css('color', '#000000');
			$(this.textDiv).css('background-color', '#ffffff');

			this.isCameraRefused = true;
			this.refused = true;
			this.lockRight = true;
			this.lockLeft = true;
			var globalState = MultiStorage.getGlobalState();
			var firstReload = true;
			// if (!SETTINGS.isAndroid) {
				globalState.cameraAttempts = globalState.cameraAttempts||0;
				globalState.cameraAttempts++;
				firstReload = globalState.cameraAttempts<2;
				MultiStorage.setGlobalState(globalState);
				// if (firstReload) {
				// }
			// }
			console.log("First reload:", SETTINGS.isIOS, firstReload);
			$(this.textDiv).toggleClass('allow-camera-text ', false);

			// if (this.info.isAutomaticReturn) firstReload = true;
			if (SETTINGS.isIOS) {
				if (firstReload) {
					$(this.textDiv).html(this.info.refusedReload);
					$(this.textDiv).toggleClass('top', false);
					$(this.textDiv).toggleClass('center', true);
				} else {
					$(this.textDiv).html(this.info.refusedIos);
					$(this.textDiv).toggleClass('top', false);
					$(this.textDiv).toggleClass('center', true);
					console.log("Setting text",this.info.refusedIos);
				}
			} else if (SETTINGS.isAndroid) {
				$(this.textDiv).html(this.info.refusedAndroid);
				$(this.textDiv).toggleClass('top', false);
				$(this.textDiv).toggleClass('center', true);
			} else {
				if (firstReload) {
					$(this.textDiv).html(this.info.refusedReload);
					$(this.textDiv).toggleClass('top', false);
					$(this.textDiv).toggleClass('center', true);
				} else {
					$(this.textDiv).html(this.info.refusedOthers);
					$(this.textDiv).toggleClass('top', false);
					$(this.textDiv).toggleClass('center', true);
				}

				$(this.textDiv).html(this.info.refusedOthers);
				$(this.textDiv).toggleClass('top', false);
				$(this.textDiv).toggleClass('center', true);
			}

			$(this.textDiv).find('#page-reload').on('click', function() {window.location.reload();});
			$(this.textDiv).css('pointer-events', 'auto').css('font-size', '1.1em').css('padding', '1em').css('padding-bottom', '1.5em');

			// $(this.textDiv).css('pointer-events', 'auto');


			$(this.textDiv).find('#page-reload').css('text-decoration', 'underline');
		}

		// if (this.doneCallback && this.params.firstTask && this.active && (window.CameraController.cameraAllowed || window.CameraController.cameraRefused)) {
		// 	this.doneCallback(this);
		// 	this.doneCallback = null;
		// 	this.lockRight = false;
		// 	this.lockLeft = false;
		// }
	}

	//
	// Render image to webgl??
	//
	render() {
		if (!this.active) return;
		super.render();

	}
};

// AllowCameraView.returnInfo = {
// 	"isAllowCamera": true,
// 	"type": "simple-branch",
// 	"camera_not_allowed/branch": [
// 		{
// 			"text": "Welcome back to Motto!<br>Click \"Allow\" to be able to<br>keep using your camera.<br>We still won't ever record any audio.",
// 			"textReload": "Sorry, you won’t be able to continue with MOTTO unless you grant it access to the camera.<br><br>We won’t submit your videos without permission, and no audio will ever be recorded.",
// 			"textType": "top",
// 			"type": "allowcamera",
// 			"background": "white"
// 		},
// 		{
// 			"isCameraRefused": true,
// 			"type": "simple-branch",
// 			"camera_refused/branch": {
// 				"text":"",
// 				"textReload": "Sorry, you won’t be able to continue with MOTTO unless you grant it access to the camera.<br><br>We won’t submit your videos without permission, and no audio will ever be recorded.",
// 				"textIos": "OK, either you really don’t want to continue with this project or else the camera is turned off in your Safari//Chrome settings.<br><br>To fix this, go to Settings &gt; Safari//Chrome &gt; Camera &amp; Microphone Access and turn it ON.<br>Then reload the page.",
// 				"textAndroid": "Ahhhhhhh!!! [sad/scary face]<br><br>To continue, you need to let MOTTO access your camera by editing Chrome’s site settings for this page. Do this by tapping the lock icon at the top of this page, choosing Site Settings, and allowing Access to Camera. Then reload the page.<br><br>We won’t submit your videos without permission, and no audio will ever be recorded.",
// 				"textOthers": "Sorry, you won’t be able to continue with MOTTO unless you grant it access to the camera.<br>We won’t submit your videos without permission, and no audio will ever be recorded.",
// 				// "Sorry, you won’t be able to continue with MOTTO unless you grant it access to the camera. We won’t submit your videos without permission, and no audio will ever be recorded."
// 				"textType": "top",
// 				"type": "camera-refused",
// 				"background": "white",
// 				"isCameraRefused": true
// 			},
// 			"default": "skip"
// 		}
// 	],
// 	"default": "skip"
// };


AllowCameraView.returnInfo = {
	// "type": "simple-branch",
	// "camera_not_allowed/branch": {
		"text": "Welcome back to Motto!<br>Click \"Allow\" to be able to<br>keep using your camera.<br>We still won't ever record any audio.",
		
		// "textReload": "Sorry, you won’t be able to continue with MOTTO unless you grant it access to the camera.<br><br>We won’t submit your videos without permission, and no audio will ever be recorded.",
		// "refusedReload": "Sorry, you won’t be able to continue with MOTTO unless you grant it access to the camera.<br><br>We won’t submit your videos without permission, and no audio will ever be recorded.<br><br><a href=\"#\" id=\"page-reload\">Reload the page to continue.</a>";
		// "refusedIos": "To continue, you need to let MOTTO access your camera.<br><br>Either you really don’t want to continue with this project or else the camera is turned off in your Safari//Chrome settings.<br><br>To fix this, go to Settings &gt; Safari//Chrome &gt; Camera &amp; Microphone Access and turn it ON. Then reload the page.",
		// "refusedAndroid": "To continue, you need to let MOTTO access your camera by editing Chrome’s site settings for this page. Do this by tapping the lock icon at the top of this page, choosing Site Settings, and allowing Access to Camera. Then reload the page.<br><br>We won’t submit your videos without permission, and no audio will ever be recorded.",
		// "refusedOthers": "Sorry, you won’t be able to continue with MOTTO unless you grant it access to the camera.<br>We won’t submit your videos without permission, and no audio will ever be recorded.",

		"isAutomaticReturn": true,
		"textType": "top",
		"type": "allowcamera",
		"params": {},
		"background": "white"
	// },
	// "default": "skip"
};

AllowCameraView.chapterInfo = {
	// "type": "simple-branch",
	// "camera_not_allowed/branch": {
		"text": "We have to authorize your camera again for the chapter.",

		// "textReload": "Sorry, you won’t be able to continue with MOTTO unless you grant it access to the camera.<br><br>We won’t submit your videos without permission, and no audio will ever be recorded.",
		// "refusedReload": "Sorry, you won’t be able to continue with MOTTO unless you grant it access to the camera.<br><br>We won’t submit your videos without permission, and no audio will ever be recorded.<br><br><a href=\"#\" id=\"page-reload\">Reload the page to continue.</a>";
		// "refusedIos": "To continue, you need to let MOTTO access your camera.<br><br>Either you really don’t want to continue with this project or else the camera is turned off in your Safari//Chrome settings.<br><br>To fix this, go to Settings &gt; Safari//Chrome &gt; Camera &amp; Microphone Access and turn it ON. Then reload the page.",
		// "refusedAndroid": "To continue, you need to let MOTTO access your camera by editing Chrome’s site settings for this page. Do this by tapping the lock icon at the top of this page, choosing Site Settings, and allowing Access to Camera. Then reload the page.<br><br>We won’t submit your videos without permission, and no audio will ever be recorded.",
		// "refusedOthers": "Sorry, you won’t be able to continue with MOTTO unless you grant it access to the camera.<br>We won’t submit your videos without permission, and no audio will ever be recorded.",

		"textType": "top",
		"type": "allowcamera",
		"params": {},
		"background": "white"
	// },
	// "default": "skip"
};
export default AllowCameraView;	

