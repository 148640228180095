import MultiStorage from "../utils/MultiStorage.js";
import MobileDetect from 'mobile-detect';

function SETTINGS() {

	this.init = function() {
		this.INTERNAL_VERSION = 1.01;
		this.VERSION = window.APP_VERSION || 1.0;
		this.RELEASE_MODE = /motto.io/gi.test(window.location.href);
		this.EDIT_MODE = /storyboard|bins|editor|mod.html/gi.test(window.location.href);
		this.STORYBOARD_MODE = /storyboard.html/gi.test(window.location.href);
		this.EDITOR_MODE = /editor.html/gi.test(window.location.href);
		this.BINS_APP_MODE = /bins.html/gi.test(window.location.href);
		this.MODERATION_MODE = /mod.html/gi.test(window.location.href);
		this.QA_MODE = /.qa|qa./gi.test(window.location.href);
		this.PRESS_MODE = /press/gi.test(window.location.href);
		if (this.PRESS_MODE) this.QA_MODE = true;

		this.EVENEMENT_MODE = false; //for event

		if (this.RELEASE_MODE) window.oneAlert = true; //prevent alerts
		if (this.EDIT_MODE) this.RELEASE_MODE = false;
		//
		// >> Motto Server
		//

		//	
		// Check for window parameters
		// For Debugging or specific-resolution modes
		//
		var queryParameters = window.location.search.substr(1).split('&').concat(window.location.hash.substr(1).split('&'));
		var params = {};
		for (var i=0; i<queryParameters.length; i++) {
			var pair = queryParameters[i].split('=');
			if (pair.length > 1) params[pair[0]] = pair[1]; else params[queryParameters[i]] = true;
		}
		this.params = params;
		this.PRODUCTION_SERVER = true;

		if (params["cache"] || this.QA_MODE) this.RELEASE_MODE = false;


		var globalState = {};
		if (window.MultiStorage) {
			window.MultiStorage.testStorage();
			globalState = MultiStorage.getGlobalState();
		}
		// if (this.BINS_APP_MODE) this.PRODUCTION_SERVER = false;
		//((params["server"]!==undefined && params["server"]!=="false") ? true : false); // || this.MODERATION_MODE || this.BINS_APP_MODE;
		// if (params["server"]!==undefined && /motto-dev/.test(window.location.href)) this.PRODUCTION_SERVER = true;
		this.DIRECT_S3_MODE = true; // !!this.PRODUCTION_SERVER;

		//urls
		this.DEV_MODE = !!params['dev'] || /localhost|192.|127./.test(window.location.href);
		this.SKIP_LOGOS = params["logo"] || params["logos"] || this.EDIT_MODE;

		// this.API_SERVER_URL = this.DEV_MODE ? (window.location.toString().split(window.location.pathname)[0]+'/') : 'http://motto.nfb.ca/';
		
		// this.API_SERVER_URL = 'https://mottodev.aatoaa.com:8443';
		// this.MODERATION_SERVER_URL = 'https://mottodev.aatoaa.com:8443';
		// if (this.PRODUCTION_SERVER) {
		// 	this.API_SERVER_URL = params["server"]||"https://motto-dev.nfb.ca/api";
		// 	this.MODERATION_SERVER_URL = params["server"]||"https://motto-dev.nfb.ca/api";
		// }
		this.USERS_URL = 'https://mottodev.s3.ca-central-1.amazonaws.com/data/users.js';

		this.OTHER_ASSETS_URL = window.CONFIG.OTHER_ASSETS_URL;
		this.VIDEO_ASSETS_URL = window.CONFIG.VIDEOS_URL;
		this.API_SERVER_URL = window.CONFIG.API_SERVER_URL || 'https://mottodev.aatoaa.com:8443';
		this.MODERATION_SERVER_URL = window.CONFIG.MODERATION_SERVER_URL || 'https://mottodev.aatoaa.com:8443/moderation';


		if (this.params["local"]) {this.MODERATION_SERVER_URL = "http://localhost:8443/moderation"; this.API_SERVER_URL = "http://localhost:5003/api";}
		if (SETTINGS.EDIT_MODE) this.MODERATION_SERVER_URL = 'https://mottodev.aatoaa.com:8443/moderation';
		
		if (this.params["server"]) this.API_SERVER_URL = this.params["server"];
		if (this.params["mserver"]) this.MODERATION_SERVER_URL = this.params["mserver"];
		
		if (this.params["prod"]) {
			this.API_SERVER_URL = 'https://www.motto.io/api';
			this.MODERATION_SERVER_URL = 'http://www.motto.io/moderation';
		}
		
		if (this.params["dev"]) {
			this.API_SERVER_URL = 'https://motto-dev.nfb.ca/api';
			this.MODERATION_SERVER_URL = 'https://motto-dev.nfb.ca/moderation';
		}

		// this.API_SERVER_URL = "http://127.0.0.1:5003";
		
		this.IMAGES_URL = this.OTHER_ASSETS_URL; 
		this.DATA_URL = this.OTHER_ASSETS_URL+'data/'; //'https://s3.ca-central-1.amazonaws.com/mottodev/data/';
		this.TASKLIST_URL = window.CONFIG.JSON_URL; //'https://s3.ca-central-1.amazonaws.com/mottodev/data/';
		this.UI_IMAGES_URL = this.OTHER_ASSETS_URL+"images/"; //'https://s3.ca-central-1.amazonaws.com/mottodev/images/';
		this.VIDEOS_URL = this.VIDEO_ASSETS_URL+'videos/';
		this.SPRITESHEET_URL = this.VIDEO_ASSETS_URL;

		if (this.params["devjson"] || this.params["json"]) {
			this.TASKLIST_URL = 'https://mottodev.s3.ca-central-1.amazonaws.com/data/';
		}

		this.BETA_ID = params['user']||false;
		this.BETA_MODE = !!(/september/.test(window.location.href)) || params["beta"] || this.PRESS_MODE;
		if (this.BETA_MODE) {
			this.BLOCK_ACCESS = !this.BETA_ID;
			// if (this.BETA_ID && window.allowed_users && !window.allowed_users[this.BETA_ID]) {this.BLOCK_ACCESS = true;}
		}
		if (SETTINGS.STORYBOARD_MODE) this.BLOCK_ACCESS = false;

		if (/mec.html/.test(window.location)) {
			this.BLOCK_ACCESS = false;
			this.BETA_MODE = true;
			this.MECHANICAL_TURK_MODE = true;
			this.MENU_DISABLED = true;
		}
		// if (this.BETA_MODE) {
		// 	this.TASKLIST_URL = window.CONFIG.JSON_URL+'september/';
		// }
		if (!this.BETA_ID) this.BETA_ID = 'motto';


		this.EDIT_MODE_VIEW_WIDTH = 
			this.STORYBOARD_MODE ? 162
								 : 288;
		this.EDIT_MODE_VIEW_HEIGHT = 
			this.STORYBOARD_MODE ? 288
								 : 512;
	

		this.FACES_MODE = params["faces"];

		this.ANALYSIS_DEBUG = false;

		this.MPEG_ENABLED = true; //!params["jpeg"];
		this.MPEG2_ONLY = true;
		this.MPEG2_ENABLED = true;
		this.NO_UPLOAD = !!params["noupload"];

		//page has spent a lot of time on last page
		this.WELCOME_BACK_MODE = false;

		//SELECTION DE LANGUE
		this.LANGUAGE = 'en';
		this.AUTO_LANGUAGE = true;
		if (this.AUTO_LANGUAGE) {
			if (/fr|FR/.test(navigator.language)) {
				this.LANGUAGE = 'fr';
			}
			if (navigator.languages) {
				for (var i=0; i<navigator.languages; i++) {
					if (/fr|FR/.test(navigator.languages[i])) this.LANGUAGE = 'fr';
				}
			}
		}
		if (globalState.language) this.LANGUAGE = globalState.language;

		if (params["fr"]) {this.LANGUAGE = 'fr'; this.AUTO_LANGUAGE = false;}
		if (params["en"]) {this.LANGUAGE = 'en'; this.AUTO_LANGUAGE = false;}
		if (params['lng']) {this.LANGUAGE = params['lng'].toLowerCase(); this.AUTO_LANGUAGE = false;}
		if (this.MECHANICAL_TURK_MODE) {
			this.LANGUAGE = 'en';
			this.SKIP_LOGOS = true;
		}


		//
		// Select current task based on cookies and history and stuff
		//
		var tasklist_id = params["t"] || "";

		if (window.MultiStorage) {
			if (tasklist_id) {
				globalState.taskId = tasklist_id;
				MultiStorage.setGlobalState(globalState);
			} else {
				var storedId = globalState.taskId;
				if (storedId) {
					tasklist_id = storedId;
					console.log("Setting task from stored id:",storedId);
					this.NO_TASK = false;
				}
			}
		}

		var historyLanguage = false;
		if (!tasklist_id && window.history && window.history.state && window.history.state.taskId) {
			console.log("Setting taskId from history state", window.history.state, MultiStorage.getGlobalState());
			tasklist_id = window.history.state.taskId;
			console.log("Setting task from history:",tasklist_id);
			this.NO_TASK = false;

			if (!globalState.language && window.history.state.language && /fr|en/.test(window.history.state.language) && this.AUTO_LANGUAGE) this.LANGUAGE = window.history.state.language;
			if (!globalState.cameraAllowedOnce && window.history.state.camera) {
				globalState.cameraAllowedOnce = true;
				MultiStorage.setGlobalState(globalState);
			}

		}
		if (this.PRESS_MODE && !tasklist_id) tasklist_id = 'in1'; //no intro for press?
		if (!tasklist_id) tasklist_id = 'intro';
		this.NO_TASK = !(tasklist_id);

		if (this.MECHANICAL_TURK_MODE && (this.NO_TASK || !(/mec/.test(tasklist_id)))) {
			this.BLOCK_ACCESS = true;
		}


		if (!tasklist_id.startsWith("tasks_")) tasklist_id = "tasks_"+tasklist_id;
		if (!tasklist_id.endsWith(".json")) tasklist_id = tasklist_id+".json";

		this.tasklist_id = tasklist_id;
		this.tasklist_id_loading = tasklist_id
		if (this.LANGUAGE=='fr' && !tasklist_id.endsWith("_fr.json")) {
			if (/in1|in2|in3|in4|out1|out2|out3|out4|ghost5|end6/.test(tasklist_id)) {
				tasklist_id = tasklist_id.replace('.json', '_fr.json');
			}
		}
		this.tasklist_id_json = tasklist_id

		this.INTRO_MODE = /intro/.test(this.tasklist_id) || params["intro"];
		this.WAS_INTRO_MODE = this.INTRO_MODE;
		if (!this.INTRO_MODE) this.SKIP_LOGOS = true;


		this.MODE_AUTO = params['auto']; // || this.isDesktop;
		this.VIDEOLIST_CACHED = this.PRODUCTION_SERVER || this.BETA_MODE || (params["fast"] && params["fast"]!=="false") || (params["fast"]!=="false" && /localhost|192\.168/gi.test(window.location.href));

		this.TXT_BRANCHES = true; //params["branches"]||params["branch"];
		this.FORCE_ERROR = params["error"]?parseInt(params["error"],10):0;

		this.FORCE_WELCOME_BACK = params["welcomeback"];

		//quality
		this.SPRITESHEET_QUALITY = 'q24';
		this.FORCE_DAY = !!params['day'];
		this.FORCE_NIGHT = !!params['night'];

		//capabilities
		this.isMobile = navigator.userAgent.toLowerCase().match(/(ipad|iphone|ipod|android|webos|blackberry|tablet|mobile|iemobile|windows phone)/gi) ? true : false;
		this.isDesktop = !this.isMobile;
		// this.isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(navigator.userAgent.toLowerCase());
		// this.isMobile = true;
		this.isSamsungBrowser = navigator.userAgent.match(/SamsungBrowser/gi) ? true : false;
		this.isAndroid = navigator.userAgent.match(/Android/gi) ? true : false;
		this.isIOS = (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
		this.isFirefox = navigator.userAgent.match(/Firefox/gi) ? true : false;

		// !!navigator.platform && /ipad|iphone|ipod/.test(navigator.platform.toLowerCase());
		//(!!navigator.userAgent.match(/iPhone|iPad|iPod/gi)); // ? true : false;
		this.isChrome = navigator.userAgent.match(/Chrome/gi) ? true : false;
		this.isSafari = !this.isChrome && navigator.userAgent.match(/Safari/gi) ? true : false;

		// this.isTablet = /iPad|ipad|tablet|Tablet/gi.test(navigator.userAgent);
		if (this.isSafari && !this.isTablet) {
			this.isTablet = navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform);
		}
		try {
			window.mobileDetect = new MobileDetect(navigator.userAgent);
			this.isTablet = this.isTablet||window.mobileDetect.tablet();
		} catch (err) {}
	


		this.FORCE_SUPPORT = params["support"];
		this.FULLSCREEN = params["fs"];
		this.OUTSIDE_ALLOWED = true;
		if (params["covid"]||params["inside"]) this.OUTSIDE_ALLOWED = false;
		if (params["outside"]) this.OUTSIDE_ALLOWED = true;


		if (this.BLOCK_ACCESS) this.SKIP_LOGOS = true;
		// this.OUTSIDE_ALLOWED = params["outside"] || false;

		if (params['forceios']) {this.isIOS = true; this.isAndroid = false; this.FAKE_IOS = true;}


		this.GHOST5_FONT = params["ghost5"];

		this.HIGH_QUALITY_BINS = params["hq"];

		this.OLD_PHONE_MODE = params["low"];


		this.LOADING_ERROR_MODE = params["loadingerror"];
		this.USER_LOADING_ERROR_MODE = params["usererror"];
		this.SLOW_LOADING = params["slowloading"];
		this.LOWRES_CAMERA_MODE = params["lowres"];


		//
		// AUTO-FTP ON FACEBOOK AND INSTAGRAM
		//
		this.ENABLE_AUTO_FTP = this.isIOS && window.CONFIG.ENABLE_AUTO_FTP && (navigator.userAgent.match(/Instagram|FBAN|FBAV|FB4A/gi) ? true : false);
		// /Instagram|FBAN|FBAV/gi.test(navigator.userAgent);
		this.FTP_LINK = window.CONFIG.FTP_LINK;

		this.ENABLE_AUTO_INTENT = this.isAndroid && window.CONFIG.ENABLE_AUTO_INTENT && (navigator.userAgent.match(/Instagram|FBAN|FBAV|FB4A/gi) ? true : false);
		this.INTENT_LINK = "intent:https://www.motto.io#Intent;end";


		//
		// BINS MODE
		//
		this.SHOW_USER_ONLY = params['user'];

		this.ARCHIVES_MODE = params["archives"] || params["photos"] || params["aatoaa"];
		this.PHOTOS_MODE = params["photos"];
		this.BINS_INTERESTING_MODE = params["interesting"];
		this.BINS_ALL_VIDEOS_MODE = params["allvideos"] || this.SHOW_USER_ONLY || this.ARCHIVES_MODE || this.PHOTOS_MODE || this.MODERATION_MODE || this.BINS_INTERESTING_MODE;
		this.BINS_DROPDOWN = !this.BINS_ALL_VIDEOS_MODE;
		this.SHOW_HIDDEN = true;

		this.BINS_ALL_MODE = params["all"];
		this.BINS_NO_SCROLL = params["noscroll"];
		this.BINS_PRE_RELEASE = !!params["pre"];
		this.BINS_POST_RELEASE = !!params["post"];
		this.BINS_ONLY = params["bin"];

		this.SHOW_DELETED_ONLY = this.SHOW_DELETED = params['deleted'];
		this.SHOW_HIDDEN_ONLY = params['hidden'] || params["non"];
		this.SHOW_APPROVED_ONLY = params['approved'] || params["oui"];
		this.SHOW_MODERATED_ONLY = params['moderated']; // || this.SHOW_APPROVED_ONLY;
		this.SHOW_UNMODERATED_ONLY = params['unmoderated'] || params["new"];
		// this.SHOW_INTERESTING_ONLY = params['unmoderated'] || params["new"];


	}
};

window.SETTINGS = new SETTINGS();
export default window.SETTINGS;
