import $ from 'jquery';
import * as THREE from 'three';

import SETTINGS from '../controllers/Settings.js';
import AppStatus from '../controllers/AppStatus.js';
import Utils from '../utils/Utils.js';
import Fbo from '../utils/Fbo.js'
import MultiStorage from '../utils/MultiStorage.js';


import LoaderXHR from '../loading/LoaderXHR.js';
import PageLoader from '../loading/PageLoader.js';
import Loader from '../loading/Loader.js';


import RendererController from '../controllers/RendererController.js';
import UserVideoController from '../controllers/UserVideoController.js';
import CameraController from '../controllers/CameraController.js';
import AnalysisController from '../controllers/AnalysisController.js';
import OpticalFlowController from '../controllers/OpticalFlowController.js';
import FrameRecorder from '../controllers/FrameRecorder.js';
import FaceTracker from '../controllers/FaceTracker.js'
import MaterialController from "../controllers/MaterialController.js";
import MontageController from '../controllers/MontageController.js';

import Montage from '../objects/Montage.js';
import SpritesheetVideo from '../objects/SpritesheetVideo.js';
import FaceRenderer from '../objects/FaceRenderer.js'

import MottoView from './MottoView.js';


//------------------------
// 
//  Base class for content view
//  Handles webgl texture creating & disposing & fill options
//
//-------------------------
class RecordView extends MottoView {

	constructor(_info, batchName) {
		super(_info, batchName);

		this.fillType = this.params.fill;

		this.textType = this.info["textType"] || 'top';
		this.textBackground = (this.info.textBackground || (this.info.textBackground==undefined && this.info.textBackground)) ? (this.info.textBackground || ( this.info.dark ? '#000000' : '#ffffff')) : '#ffffff';

		this.captureYesImg = PageLoader.addImage(batchName, SETTINGS.OTHER_ASSETS_URL+'images/ui/capture_yes.png');
		this.captureNoImg = PageLoader.addImage(batchName, SETTINGS.OTHER_ASSETS_URL+'images/ui/capture_redo.png');
		this.captureSkipImg = PageLoader.addImage(batchName, SETTINGS.OTHER_ASSETS_URL+'images/ui/skip.png');
		this.cameraImg = PageLoader.addImage(batchName, SETTINGS.OTHER_ASSETS_URL+'images/ui/camera.png');
		this.videoWidth = 256;
		this.videoHeight = 256;
		this.lockRight = !SETTINGS.MODE_AUTO;
		this.skipped = false;
		this.pageNumberWhite = true;
		this.hasLivefeed = true;
		this.livefeedDirection = this.params.camera || "back";

		this.backgroundColor = '#000000';

		//
		// Create accept screen
		//
		this.isLiveFeed = false;
		this.confirming = false;
		this.confirmed = false;
		this.recordStartTime = 0;
		this.recording = false;
		this.recordButtonShown = false;
		this.recorded = false;
		this.recordedTextures = null;
		this.confirmSpritesheetVideo = null;
		this.confirmButtonShown = false;
		this.lastCameraTime = 0;

		// this.cameraRecImg = Loader.addXHR(batchName, 'images/ui/camera.png', 'blob');
		this.spritesheetVideo = null;
		this.doneCallback = null;

		this.facesInfo = null;
		this.lastFlowX = 0.5;
		this.lastFlowY = 0.5;


		if (this.params.reference) {
			this.referenceTexture = Loader.addTexture(batchName, SETTINGS.UI_IMAGES_URL+this.params.reference, {
				format: THREE.RGBAFormat,
				wrapping: THREE.ClampToEdgeWrapping,
				generateMipmaps: false,
				minFilter: THREE.LinearFilter
			});
		}
		
		if (this.params.referenceMontage) {
			this.referenceMontage = new Montage(this.params.referenceMontage, batchName); //MontageController.create(this.params);
		}


		if (this.params.faces) {
			this.faceRenderer = new FaceRenderer();
			this.faceRenderer.isLive = true;
			this.faceRenderer.setup(this.params);
			this.faceRenderer.preload(batchName);
			FaceTracker.preload(batchName);
		}

		if (this.params.tracking) {
			// OpticalFlowController.preload(batchName);
		}
	}

	setupRecord(batchName) {
		//--------------
		//
		// Reload!
		//
		//--------------
		this.recordId = this.params.id || UserVideoController.getRecordId(this.params.bin);
		var tasksState = MultiStorage.getState();
		if (tasksState.userVideos[this.recordId] && MontageController.userVideos[tasksState.userVideos[this.recordId]]) {
			this.confirmed = true;
			this.lockRight = false;
			this.doneCallback = null;
			if (SETTINGS.ANALYSIS_DEBUG) {
				this.params.effect = this.params.effect || ((this.params.analysis && SETTINGS.ANALYSIS_DEBUG) ? AnalysisController.ANALYSIS_DEBUG_DEFAULTS[this.params.analysis] : null);
			}
			this.exportSpritesheet = MontageController.createSpritesheetFromReloadedUserVideo(tasksState.userVideos[this.recordId], this.params, this.recordId, batchName);
			
			if (SETTINGS.ANALYSIS_DEBUG) {
				// this.exportSpritesheet.params.analysis = this.exportSpritesheet.params.analysis;
				this.exportSpritesheet.params.effect = this.exportSpritesheet.params.effect || ((this.exportSpritesheet.params.analysis && SETTINGS.ANALYSIS_DEBUG) ? AnalysisController.ANALYSIS_DEBUG_DEFAULTS[this.exportSpritesheet.params.analysis] : null);
				this.exportSpritesheet = this.exportSpritesheet.clone();
				this.exportSpritesheet.setup(this.exportSpritesheet.params, this.exportSpritesheet.frames, this.exportSpritesheet.framesType);
			}
		}
	}


	start() {
		if (this.started) return;
		super.start();

		this.lockRight = (FrameRecorder.recording || this.confirming || !this.skipped) && !SETTINGS.MODE_AUTO && !this.isLiveFeed;

		if (this.params.tracking || this.faceRenderer || (this.params.effect && this.params.effect.spinMode)) OpticalFlowController.setupCV();


		if (this.params.effect && this.params.effect.type == "motionDelay") {
			this.pageNumberWhite = false;
			this.fbos = [];
			for (var i=0; i<24; i++) {
				this.fbos.push(SpritesheetVideo.getFbo(288, 512));
			}
		}
		if (this.params.effect && this.params.effect.type == "mirror" && this.params.analysis == 'faces') {
			this.fbos = [];
			for (var i=0; i<2; i++) {
				this.fbos.push(SpritesheetVideo.getFbo(288, 512));
			}
		}


		//------------------------
		//
		// Create the webgl scene
		//
		//------------------------
		this.scene = new THREE.Scene();
		// CameraController.texture = CameraController.texture;
		// this.playbackMaterial = (this.params.reference || this.params.referenceMontage) ? MaterialController.getMaterial('playback_reference', {
		// 			"BLEND_MODE": (
		// 				this.params.referenceBlend=='multiply' ? 4 :
		// 				this.params.referenceBlend=='difference' ? 3 :
		// 				this.params.referenceBlend=='screen' ? 2 :
		// 				this.params.referenceBlend=='add' ? 1 : 0)
		// }) : MaterialController.getMaterial('playback');
		if (this.params.effect && (this.params.effect.type == "mirror" && !this.params.reference)) {

			this.playbackMaterial = MaterialController.getMaterial('playback_mirror');
			this.playbackMaterialSimple = MaterialController.getMaterial('playback');

		} else if (this.params.effect && this.params.effect.type == "motionDelay") {
			
			this.playbackMaterial = MaterialController.getMaterial('playback_motion_delay', {
				"INVERT_COLORS": this.params.effect&&this.params.effect.invert ? 1 : 0
			});
			if (this.params.effect.colors) {
				this.playbackMaterial.uniforms.colorA.value.set(this.params.effect.colors[0]);
				this.playbackMaterial.uniforms.colorB.value.set(this.params.effect.colors[1]);
				this.playbackMaterial.uniforms.colorC.value.set(this.params.effect.colors[2]);
			} else {
				this.playbackMaterial.uniforms.colorA.value.set(0xff00ff);
				this.playbackMaterial.uniforms.colorB.value.set(0x00ffff);
				this.playbackMaterial.uniforms.colorC.value.set(0xffff00);
			}
			this.playbackMaterial.uniforms.revealpc.value = this.params.effect&&this.params.effect.spinMode ? 0.0 : 1.0;

		} else if (this.params.reference || this.params.referenceMontage) {

			this.playbackMaterial = (this.params.reference || this.params.referenceMontage) ? MaterialController.getMaterial('playback_reference', {
						"BLEND_MODE": (
							this.params.referenceBlend=='multiply' ? 4 :
							this.params.referenceBlend=='difference' ? 3 :
							this.params.referenceBlend=='screen' ? 2 :
							this.params.referenceBlend=='add' ? 1 : 0),
						"MIRROR": (this.params.effect&&this.params.effect.type) == "mirror"?1:0,
						"FEATHER": this.params.referenceFeather?1:0
			}) : MaterialController.getMaterial('playback');

			if (this.referenceMontage) {
				this.referenceMontage.contentDiv = this.contentDiv;
				this.referenceMontage.replaceUserVideos();
				this.referenceMontage.prepare();
			}

		} else {
			this.playbackMaterial = MaterialController.getMaterial('playback');			
		}

		this.plane = new THREE.Mesh(Utils.planeGeometry, this.playbackMaterial);
		this.playbackMaterial.uniforms.ratio.value.set(1,1);
		this.plane.scale.set(1,1,1);
		this.scene.add(this.plane);
		this.camera = new THREE.OrthographicCamera( -0.5, 0.5, 0.5, -0.5, -0.5, 0.5 );

		if (this.referenceMontage) {
			this.referenceMontage.contentDiv = this.contentDiv;
			this.referenceMontage.replaceUserVideos();
			this.referenceMontage.prepare();
		}
		if (this.faceRenderer) {
			this.faceRenderer.prepare();
			FaceTracker.activate();
		}

		this.currentMotionTime = 0.0;



		//
		// Add Buttons
		//
		if (!this.isLiveFeed) {
			// $(this.pageCounterDiv).hide();

			this.recordButtonCanvas = document.createElement('canvas');
			this.recordButtonCanvas.width = 202;
			this.recordButtonCanvas.height = 202;
			this.recordButtonContext = this.recordButtonCanvas.getContext('2d');
			$(this.recordButtonCanvas).addClass('record-image');
			$(this.recordButtonCanvas).css('pointer-events', 'none');
			this.buttonCleared = false;

			this.recordButton = document.createElement('div');
			$(this.recordButton).addClass('record-button');
			$(this.recordButton).append(this.recordButtonCanvas);
			$(this.contentDiv).append(this.recordButton);

			this.confirmButton = document.createElement('div');
			$(this.confirmButton).addClass('confirm-button');
			$(this.contentDiv).append(this.confirmButton);

			this.cancelButton = document.createElement('div');
			$(this.cancelButton).addClass('cancel-button');
			$(this.contentDiv).append(this.cancelButton);

			this.skipButton = document.createElement('div');
			$(this.skipButton).addClass('skip-button');
			$(this.contentDiv).append(this.skipButton);

			$(this.confirmButton).on(SETTINGS.isMobile ? 'touchend' : 'click', function(e) {
				if (!this.confirming) return;
				AnalyticsController.trackEvent('record', this.recordId);

				this.confirming = false;
				this.confirmed = true;
				FrameRecorder.recording = false;
				$(this.confirmButton).css('visibility', 'hidden');
				$(this.cancelButton).css('visibility', 'hidden');
				$(this.recordButton).hide();
				$(this.skipButton).hide();
				this.lockRight = false;
				this.exportSpritesheet = FrameRecorder.export();
				BranchController.setRecord(this.params.id||this.recordId);
				if (this.doneCallback) this.doneCallback(this);
				this.doneCallback = null;

				this.animationStarted = true;
				this.animationPc = 0.0;
				this.animationStartTime = performance.now()+(this.params.effect ? (this.params.effect.animationDelay||0):0 )*1000;
				this.maxActive = -1;

				if (this.info.confirmMessage) $(this.textDiv).html(Utils.replaceGender(this.info.confirmMessage, AppStatus.SEPTEMBER_GENDER));

				e.stopPropagation();
				e.preventDefault();
				return false;
			}.bind(this));
			
			this.cancelConfirmed = function(e) {
				if (!this.confirming) return;
				AnalyticsController.trackEvent('cancel_record', this.recordId);

				FrameRecorder.recording = false;
				this.confirming = false;
				$(this.recordButton).show();
				if (!SETTINGS.MECHANICAL_TURK_MODE) $(this.skipButton).show();
				this.recordButtonShown = true;
				this.recording = false;
				this.buttonCleared = false;
				this.lockLeft = this.wasLockedLeft;
				this.lockRight = this.wasLockedRight;
				this.confirmSpritesheetVideo.stop();
				if (this.info.confirmMessage) $(this.textDiv).html(Utils.replaceGender(this.info.description?this.info.description[SETTINGS.LANGUAGE]:(this.info.text||''), AppStatus.SEPTEMBER_GENDER));
				

				this.animationStarted = true;
				this.animationPc = 0.0;
				this.animationStartTime = performance.now()+(this.params.effect ? (this.params.effect.animationDelay||0):0 )*1000;
				this.maxActive = -1;

				if (this.faceRenderer) this.faceRenderer.activate(this.contentDiv);
				$(this.confirmButton).css('visibility', 'hidden');
				$(this.cancelButton).css('visibility', 'hidden');
				e.stopPropagation();
				e.preventDefault();
				return false;
			}.bind(this);
			$(this.cancelButton).on(SETTINGS.isMobile ? 'touchend' : 'click', this.cancelConfirmed);

			$(this.recordButton).hide();
			$(this.skipButton).hide();
			$(this.recordButton).on(SETTINGS.isMobile ? 'touchstart': 'click', function(e) {
				if (this.recording || this.recorded || this.confirming || this.exporting) {
					return;
				}
				AnalyticsController.trackEvent('recording', this.recordId);

				this.animationStarted = true;
				this.animationPc = 0.0;
				this.animationStartTime = performance.now()+(this.params.effect ? (this.params.effect.animationDelay||0):0 )*1000;
				this.maxActive = -1;

				this.savedFlowTracking = [];
				$(this.skipButton).hide();
				this.recording = true;
				this.wasLockedLeft = this.lockLeft;
				this.lockLeft = true;

				this.wasLockedRight = this.lockRight;
				this.lockRight = true;
				FrameRecorder.record(this.params, this.info, this.recordId);
				this.facesInfo = {faces:[], isLive: true};
				e.stopPropagation();
				e.preventDefault();
				return false;
			}.bind(this));


			$(this.skipButton).on(SETTINGS.isMobile ? 'touchend' : 'click', function(e) {
				AnalyticsController.trackEvent('skip', this.recordId);
				//stop recording
				// console.log("SKIP BTN", this.doneCallback);
				BranchController.setSkip(this.params.id||this.recordId);
				this.lockRight = false;
				this.skipped = true;
				if (this.doneCallback) this.doneCallback(this);
				e.stopPropagation();
				e.preventDefault();
				return false;
			}.bind(this));


			this.recordButton.addEventListener('touchstart', this.cancelZoom, {passive: false}, true);
			// this.confirmButton.addEventListener('touchstart', this.cancelZoom, {passive: false}, true);
			// this.skipButton.addEventListener('touchstart', this.cancelZoom, {passive: false}, true);
			// this.cancelButton.addEventListener('touchstart', this.cancelZoom, {passive: false}, true);
			this.recordButton.addEventListener('touchmove', this.cancelZoom, {passive: false}, true);
			// this.confirmButton.addEventListener('touchmove', this.cancelZoom, {passive: false}, true);
			// this.skipButton.addEventListener('touchmove', this.cancelZoom, {passive: false}, true);
			// this.cancelButton.addEventListener('touchmove', this.cancelZoom, {passive: false}, true);
			this.recordButton.addEventListener('touchend', this.cancelZoom, {passive: false}, true);
			// this.confirmButton.addEventListener('touchend', this.cancelZoom, {passive: false}, true);
			// this.skipButton.addEventListener('touchend', this.cancelZoom, {passive: false}, true);
			// this.cancelButton.addEventListener('touchend', this.cancelZoom, {passive: false}, true);

			if (this.confirmed || this.confirming)	{
				if (this.info.confirmMessage) $(this.textDiv).html(Utils.replaceGender(this.info.confirmMessage, AppStatus.SEPTEMBER_GENDER));
				$(this.confirmButton).css('visibility', 'hidden');
				$(this.cancelButton).css('visibility', 'hidden');
				$(this.recordButton).hide();
				$(this.skipButton).hide();
				this.exportSpritesheet.prepare();
			}
		}
	}

	cancelZoom(event) {
		 // if ((event.scale!==undefined && event.scale!=1)  || event.touches.length>1) {
		  	// console.log("cancel!");
		  	event.preventDefault(); return false; 
		  // }
	}

	refocus() {
		if (!this.confirmed) {
			console.log("GOT REFOCUS EVENT");
			CameraController.start(this.params.camera || "back");
		}
	}

	activate() {
		if (this.active) return;
		super.activate();
		if (!this.confirmed) {
			CameraController.start(this.params.camera || "back");
		} else {
			this.exportSpritesheet.prepare();
		}
		FrameRecorder.activeId = this.recordId;
		this.lastTime = 0;
		this.maxActive = -1;
		this.animationStarted = false;
		this.frameOffset2 = 0;
		this.frameOffset3 = 0;
		this.animationStartTime = 0.0;
		this.animationPc = 0.0;
		this.currentMotionTime = 0.0;

		if (this.referenceMontage) {
			this.referenceMontage.contentDiv = this.contentDiv;
			this.referenceMontage.replaceUserVideos();
			this.referenceMontage.prepare();
			this.referenceMontage.activate();
		}

		if (this.faceRenderer) this.faceRenderer.activate(this.contentDiv);


		if (this.params.tracking || (this.params.effect && this.params.effect.spinMode)) {
			OpticalFlowController.activate();

			this.trackedScene = new THREE.Scene();
			this.trackedPlane = new THREE.Mesh(Utils.planeGeometryCorner, new THREE.MeshBasicMaterial({color:0xff000, side:THREE.DoubleSide, depthTest: false, depthWrite: false}))
			this.trackedPlane.scale.set(0.05,0.05);
			this.trackedScene.add(this.trackedPlane);
		}

		window.currentView = this;
		if (this.confirmed) this.lockRight = false;



		// if (this.confirmSpritesheetVideo && this.confirming) {
		// 	this.confirmSpritesheetVideo.stop();
		// 	this.confirmSpritesheetVideo.play(this.contentDiv);
		// 	this.confirmSpritesheetVideo.contentDiv = this.contentDiv;
		// 	this.confirmSpritesheetVideo.textDiv = this.textDiv;
		// }
		// if (this.exportSpritesheet && this.confirmed) {
		// 	this.exportSpritesheet.stop();
		// 	this.exportSpritesheet.play(this.contentDiv);
		// 	this.exportSpritesheet.contentDiv = this.contentDiv;
		// 	this.exportSpritesheet.textDiv = this.textDiv;
		// }

	}

	//remove from dom
	deactivate() {
		if (!this.active) return;
		super.deactivate();
		CameraController.stop(this.params.camera || "back");
		if (this.faceRenderer) this.faceRenderer.deactivate();

		if (this.trackingDiv) {
			$(this.trackingDiv.container).remove();
			$(this.trackingDiv.div).remove();
			this.trackingDiv = null;
		}
		if (this.confirmSpritesheetVideo) this.confirmSpritesheetVideo.stop();
		if (this.exportSpritesheet) this.exportSpritesheet.stop();
		if (this.info.saveTimestamp) {
			var globalState = MultiStorage.getGlobalState();
			if (globalState.savedTimestamp) globalState.savedTimestamp[this.info.saveTimestamp] = new Date().getTime();
			MultiStorage.setGlobalState(globalState);
		}
	}

	stop() {
		if (!this.started) return;
		super.stop();
		// CameraController.texture.dispose();
		// CameraController.texture.needsUpdate = false;
		// CameraController.texture = null;

		
		if (this.fbos) {
			for (var i=0; i<this.fbos.length; i++) {
				// this.fbos[i].dispose();
				SpritesheetVideo.disposeFbo(this.fbos[i]);
				this.fbos[i].active = false;
			}
			this.fbos = null;
		}

		this.scene = null;
		this.playbackMaterial.dispose();
		this.plane = null;
		this.camera = null;


		this.confirming = false;
		this.recording = false;
		this.lockLeft = this.wasLockedLeft;
		this.recordButtonShown = false;
		this.buttonCleared = false;

		this.recordButtonCanvas = null;
		this.lastCameraTime = 0;
		if (!this.isLiveFeed) {
			$(this.recordButton).remove();
			$(this.confirmButton).remove();
			$(this.cancelButton).remove();
			$(this.skipButton).remove();
			$(this.confirmButton).off('touchend');
			$(this.cancelButton).off('touchend');
			$(this.recordButton).off('touchstart');
			$(this.skipButton).off('touchend');
			this.recordButton.removeEventListener('touchstart', this.cancelZoom, true);
			this.recordButton.removeEventListener('touchmove', this.cancelZoom, true);
			this.recordButton.removeEventListener('touchend', this.cancelZoom, true);
			this.started = false;
			this.recordButton = this.confirmButton = this.cancelButton = this.skipButton = null;

			if (this.exportSpritesheet && this.exportSpritesheet.isReady()) {
				this.exportSpritesheet.stop();
				this.exportSpritesheet.dispose();
			}

			if (this.confirmSpritesheetVideo && SETTINGS.ANALYSIS_DEBUG) {
				this.confirmSpritesheetVideo.stop();
				this.confirmSpritesheetVideo.dispose();
				this.confirmSpritesheetVideo = null;
			}
		}
		if (this.playbackMaterialSimple) this.playbackMaterialSimple.dispose();

		if (this.referenceMontage) this.referenceMontage.dispose();
		if (this.referenceTexture) this.referenceTexture.dispose();

		if (this.faceRenderer) {
			this.faceRenderer.dispose();
		}

		if (this.trackingDiv) {
			$(this.trackingDiv.container).remove();
			$(this.trackingDiv.div).remove();
			this.trackingDiv = null;
		}
	}


	forceRedraw(element){

	    if (!element) { return; }

	    var n = document.createTextNode(' ');
	    var disp = element.style.display;  // don't worry about previous display style

	    element.appendChild(n);
	    element.style.display = 'none';

	    setTimeout(function(){
	        element.style.display = disp;
	        n.parentNode.removeChild(n);
	    },20); // you can play with this timeout to make it as short as possible
	}

	update() {
		if (!this.started) return;
		super.update();



		if (this.referenceMontage) {
			this.referenceMontage.contentDiv = this.contentDiv;
			if (this.positionX > -0.001 || AppStatus.goingBackwards) {
				if (!this.active) {
					this.referenceMontage.replaceUserVideos();
				}
				this.referenceMontage.prepare();
			}
		}
		if (!this.active && this.faceRenderer) {
			this.faceRenderer.prepare();
		}

		if (!this.active) return;

		var tracked = false;
		

		// window.ttt = !window.ttt;
		// $(window).trigger('resize');

		// window.ttt = window.ttt||0;

		// var bg = $('#background')[0];
		//   var disp = bg.style.display;
		//   bg.style.display = 'none';
		  
		//    var element = CameraController.getVideo();
		//   var disp = element.style.display;
		//   element.style.display = 'none';
		//   var trick = element.offsetHeight;
		//   element.style.display = disp;

		  // window.ttt += bg.offsetHeight;
		  // bg.style.display = 'block';

		 

		// $('#background').css('transform', window.ttt ? 'rotateZ(1deg)' : 'none');
		// $('#main').css('transform', 'none');
		// $('#background').hide(10).show(10);
		// window.ttt += CameraController.getVideo().offsetHeight;
		// $(CameraController.getVideo()).hide(10).show(10);
		// window.ttt += CameraController.getVideo().offsetHeight;

		// if (SETTINGS.isIOS) {

		// console.log('redraw');
		if (SETTINGS.isIOS) { // && CameraController.isReady() && this.active && !this.confirmed
			
			// console.log('yo wtf');
			// document.body.focus()
			// $('#usermedia')[0].focus();
			// $('#usermedia')[0].dispatchEvent(new MouseEvent('click', {
			// 	bubbles: true,
			// 	cancelable: false,
			// 	view: window
			// }));
			// document.body.dispatchEvent(new MouseEvent('click', {
			// 	bubbles: true,
			// 	cancelable: false,
			// 	view: window
			// }));

			// renderer.domElement.dispatchEvent(new MouseEvent('click', {
			// 	bubbles: true,
			// 	cancelable: false,
			// 	view: window
			// }));

			// document.body.replaceWith(document.body);
			// window.getComputedStyle(document.body);
			// document.body.scrollIntoView();

			// $('body').hide();
			// document.body.focus();
			// // this.forceRedraw($('#umhack')[0]);

			// $('body').show();
			// console.log(document.body.offsetHeight);
			// renderer.domElement.focus();
			// document.body.scrollIntoView();
			


			//=========
			//
			// Final Working Fix
			//
			//========
			// $(document.body).toggleClass('force-redraw', AppStatus.currentFrame%2==0?true:false);
			// if (CameraController.isReady()) {
			// 	CameraController.getVideo().focus();
			// 	CameraController.getVideo().dispatchEvent(new MouseEvent('click', {
			// 		bubbles: true,
			// 		cancelable: false,
			// 		view: window
			// 	}));
			// 	CameraController.getVideo().scrollIntoView();
			// 	CameraController.getVideo().innerText = Math.random().toString();
			// }





			
			// $('#head').toggleClass('force-redraw', true);
			// window.getComputedStyle(document.body);
			// document.body.focus();
			// $('#head').toggleClass('force-redraw', false);
			// window.getComputedStyle(document.body);
			// document.body.focus();

			
			// document.body.offsetHeight;

			// if (AppStatus.currentFrame%2==0) {
			// 	// var main = $('#main')[0];
			// 	// $('#main').remove();
			// 	$('#main-container').hide();
			// 	window.getComputedStyle(document.body);
			// 	$('#main-container2').append($('#main'));
			// 	$('#main-container2').append($('#background'));
			// 	$('#main-container2').show();
			// 	window.getComputedStyle(document.body);
			// } else {
			// 	$('#main-container2').hide();
			// 	window.getComputedStyle(document.body);
			// 	$('#main-container').append($('#main'));
			// 	$('#main-container').append($('#background'));
			// 	$('#main-container').show();
			// 	window.getComputedStyle(document.body);
			// }


			// $('body').hide().show(0);
			// $(document.body).hide(1).show(1);

			// this.forceRedraw($('#umhack')[0]);

			// if (CameraController.isReady()) {
				// CameraController.getVideo().pause();
				// CameraController.getVideo().play();
			// }
			// $('#main').toggleClass('force-redraw', AppStatus.currentFrame%2==1?true:false);
			// $('#usermedia').toggleClass('force-redraw', AppStatus.currentFrame%2==1?true:false);
		}

		// console.log('hide&show!');

		//
		// Update - Webcam
		//
		var needsUpdate = false;
		if (CameraController.isReady() && this.active && !this.confirmed) {

			
			// console.log("Camera time", CameraController.getVideo().currentTime, CameraController.getVideo().currentTime - this.lastCameraTime);

			this.videoWidth = 9; //CameraController.getVideo().videoWidth;
			this.videoHeight = 16; //CameraController.getVideo().videoHeight;
			// CameraController.texture.image = CameraController.getVideo();
			if (this.videoWidth/this.videoHeight > this.renderWidth()/this.renderHeight()) { //wider
				this.playbackMaterial.uniforms.ratio.value.set((this.renderWidth()/this.renderHeight())/(this.videoWidth/this.videoHeight), 1);
			} else { //taller
				this.playbackMaterial.uniforms.ratio.value.set(1, (this.renderHeight()/this.renderWidth())/(this.videoHeight/this.videoWidth));
			}

			if (CameraController.getVideo().currentTime - this.lastCameraTime >= 1/30) {
			// needsUpdate = CameraController.texture.updateFrame != AppStatus.currentFrame;
			// CameraController.texture.needsUpdate = needsUpdate;
			// CameraController.texture.updateFrame = AppStatus.currentFrame;
				needsUpdate = CameraController.updateTexture();
				// console.log("Camera showing", CameraController.getVideo().currentTime);
			}

			//---------------
			//
			// Update fbo
			//
			//---------------
			// if (this.fbos && needsUpdate && (!this.lastTime || (performance.now()-this.lastTime >= 1000/24))) {

			// 	var frameDelay = Math.ceil(Math.min(this.params.effect.delay!==undefined?this.params.effect.delay:0.2, (this.fbos.length-1.01)/24 ) * 24);

			// 	this.fbos.unshift(this.fbos.splice(frameDelay,1)[0]);
			// 	Utils.renderTexture(CameraController.texture, this.fbos[0].texture, true);
			// 	this.fbos[0].active = true;
			// 	this.maxActive++;
			// 	this.lastTime = performance.now();
			// }

			//-----------------
			//
			// Update MotionDelay
			//
			//-----------------
			// if (this.params.effect && this.params.effect.type == "motionDelay") {
			// 	this.playbackMaterial.uniforms.tDiffuse.value = this.fbos[ 0 ].texture.texture; //CameraController.texture;

			// 	if (!this.animationStarted) {
			// 		this.animationStarted = true;
			// 		this.animationPc = 0.0;
			// 		this.animationStartTime = performance.now()+(this.params.effect.animationDelay||0)*1000;
			// 	}
			// 	this.animationPc = !this.params.effect.animation ? 1.0 : Math.pow(Utils.cmap(performance.now(), this.animationStartTime+this.params.effect.animation*1000*0.25, this.animationStartTime+this.params.effect.animation*1000, 0.0, 1.0),2.0);
			// 	if (isNaN(this.animationPc)) this.animationPc = 1.0;
			


			// 	//--------------
			// 	//
			// 	// frameOffset
			// 	//
			// 	//--------------
			// 	var frameDelay = Math.min(this.params.effect.delay||0.2, (this.fbos.length-1.001)/24 ) * 24;
			// 	frameDelay = Math.min(frameDelay, this.maxActive);

			// 	var frameOffset2 = Math.round(Utils.ccmap(this.animationPc,0.0,0.5,0.0,frameDelay/2));
			// 	var frameOffset3 = Math.round(Utils.ccmap(this.animationPc,0.0,1.0,0.0,frameDelay));
			// 	frameOffset3 = Math.max(frameOffset2, frameOffset3);

			// 	this.playbackMaterial.uniforms.tDiffuse2.value = frameOffset2==0 ? CameraController.texture : this.fbos[ 0 ].texture.texture;
			// 	this.playbackMaterial.uniforms.tDiffuse2.value = frameOffset2==0 ? CameraController.texture : this.fbos[ frameOffset2 ].texture.texture;
			// 	this.playbackMaterial.uniforms.tDiffuse3.value = frameOffset3==0 ? CameraController.texture : this.fbos[ frameOffset3 ].texture.texture;
			
			// } else {
				this.playbackMaterial.uniforms.tDiffuse.value = CameraController.texture;
			// }

		} else {
			this.playbackMaterial.uniforms.tDiffuse.value = Utils.blackTexture;
		}

		if (this.active) {
			if (this.isLiveFeed || (!this.confirmed&&!this.confirming)) AppStatus.frameNeedsUpdate = true;
			else if (this.recording) AppStatus.frameNeedsUpdate = true;
			else if (this.confirming && this.confirmSpritesheetVideo) AppStatus.frameNeedsUpdate = AppStatus.frameNeedsUpdate||this.confirmSpritesheetVideo.needsUpdate;
			else if (this.confirmed && this.exportSpritesheet) AppStatus.frameNeedsUpdate = AppStatus.frameNeedsUpdate||this.exportSpritesheet.needsUpdate;
		}
		
		if (this.params.tracking || (this.params.effect && this.params.effect.spinMode)) {
			if (CameraController.isReady()) OpticalFlowController.update({
				globalCenter: (this.params.tracking && this.params.tracking.globalCenter),
				globalMotionMode: (this.params.effect && this.params.effect.spinMode)
			});
		}

		if (this.params.tracking) {

			var cx = OpticalFlowController.getCenterPoint().x;
			var cy = OpticalFlowController.getCenterPoint().y;
			if ((this.confirmed || this.confirming) && this.savedFlowTracking) {

				var cf = 0;
				if (this.confirmed && this.exportSpritesheet.isReady()) {
					cf = Math.floor(this.exportSpritesheet.lastFrame);

				} else if (this.confirming) {
					cf = Math.floor(this.confirmSpritesheetVideo.lastFrame);
				}
				this.lastFlowX = cx = this.savedFlowTracking[cf]!==undefined ? this.savedFlowTracking[cf].x : this.lastFlowX;
				this.lastFlowY = cy = this.savedFlowTracking[cf]!==undefined ? this.savedFlowTracking[cf].y : this.lastFlowY;
			}

			this.trackedPlane.position.x = cx;
			this.trackedPlane.position.y = cy;

			if (FrameRecorder.recording && !this.confirmed && !this.confirming && this.savedFlowTracking) this.savedFlowTracking[FrameRecorder.currentFrame] = OpticalFlowController.getCenterPoint().clone();

			if (!this.trackingDiv && this.contentDiv && this.params.tracking.text) {
				var dc = document.createElement('div');
				$(dc).addClass('spritesheet-overlay-text-container');
				// $(dc).css('background', 'none');
				$(this.contentDiv).append(dc);

				var d = document.createElement('div');
				$(d).addClass('alt-text');
				$(d).addClass('top');
				$(d).css('max-width', '90vw');
				if (this.params.tracking.font && /Crimson/gi.test(this.params.tracking.font)) {
					$(d).addClass('ghost');
					$(dc).addClass('ghost');

				} else if(this.params.tracking.font) {
					$(d).css('font-family', this.params.tracking.font);
				}
				// $(d).css('color', 'white');
				$(dc).append(d);
				// $(d).css('background-color', '#ffffff');

				this.trackingDiv = {
					container: dc,
					div:d,
					visible: false
				};
				if (this.textBackground) $(d).css('background-color', this.textBackground||(this.info.dark ? '#ffffff' : '#000000'));

				$(d).html(Utils.replaceGender(this.params.tracking.text||'', AppStatus.SEPTEMBER_GENDER));
			}
			
			if (this.trackingDiv) {
				var w = 50; //(p.scale.x*renderWidth);
				var h = 1;//(Math.abs(p.scale.y)*renderHeight);

				var fontSize =  Utils.clamp((w / 10), '0.75', '1.4') * this.params.tracking.scale;
				$(this.trackingDiv.div).css('font-size', fontSize +'em');
				w = Math.max(w, $(this.trackingDiv.container).width()) - 0.5;
				h = Math.max(h, $(this.trackingDiv.div).height());

				
				// $(this.trackingDiv.div).html(OpticalFlowController.totalBrightness.toPrecision(3));

				$(this.trackingDiv.container).css({
					'transform':' translate('+((cx)*this.renderWidth() - w/2)+'px,'+((cy)*this.renderHeight()-h*1.5)+'px)',
					// 'width': w+'px',
					'height': h+'px'
				});
				// console.log('translate('+((OpticalFlowController.getCenterPoint().x)*this.renderWidth())+'px,'+((OpticalFlowController.getCenterPoint().y)*this.renderHeight())+'px)');

				if (!this.trackingDiv.visible) {
					$(this.trackingDiv.container).css('display', 'table');
					this.trackingDiv.visible = true;
				}
			}
		}
		if ((this.confirmed||this.confirming) && this.trackingDiv && this.trackingDiv.visible) {
			$(this.trackingDiv.container).css('display', 'none');
			this.trackingDiv.visible = false;
		}



		if ((this.params.reference && this.referenceTexture.loaded) || this.params.referenceMontage) {
			var vw = 9;//this.referenceTexture.image.width;
			var vh = 16;//this.referenceTexture.image.height;
			if (this.params.reference) {
				vw = this.referenceTexture.image.width;
				vh = this.referenceTexture.image.height;
				this.playbackMaterial.uniforms.tReference.value = this.referenceTexture;
			} else {
				if (this.active) this.referenceMontage.update();
				this.playbackMaterial.uniforms.tReference.value = this.referenceMontage.getTexture();
				if (this.active) AppStatus.frameNeedsUpdate = AppStatus.frameNeedsUpdate||this.referenceMontage.needsUpdate;
			}

			if (vw/vh > this.renderWidth()/this.renderHeight()) { //wider
				this.playbackMaterial.uniforms.referenceRatio.value.set( (vh/vw) / (this.videoHeight/this.videoWidth) , 1.0).multiplyScalar(1.0/(this.params.referenceScale||1.0));

				// this.playbackMaterial.uniforms.referenceRatio.value.set((this.renderHeight()/this.renderWidth()) / (vw/vh), 1).multiplyScalar(1.0/(this.params.referenceScale||1.0));
			} else { //taller
				this.playbackMaterial.uniforms.referenceRatio.value.set( (vh/vw) / (this.videoHeight/this.videoWidth) , 1.0).multiplyScalar(1.0/(this.params.referenceScale||1.0));
			}

			this.playbackMaterial.uniforms.referenceAlpha.value = this.params.referenceAlpha !== undefined ? this.params.referenceAlpha : 1.0;
			this.playbackMaterial.uniforms.referenceOffset.value.y = (this.params.referenceOffset||0.0)

			if (this.params.tracking) {
				if (vh>=vw) {
					this.playbackMaterial.uniforms.referenceOffset.value.x = (0.5-cx) * (this.renderWidth()/this.renderHeight()) * (vw/vh);
					this.playbackMaterial.uniforms.referenceOffset.value.y = (cy - 0.5) * (vh/vw) * (this.renderWidth()/this.renderHeight()) + (this.params.referenceOffset||0.0);
				} else {
					this.playbackMaterial.uniforms.referenceOffset.value.x = (0.5-cx) * (this.renderWidth()/this.renderHeight()) / (vw/vh);
					this.playbackMaterial.uniforms.referenceOffset.value.y = (cy - 0.5) * (vw/vh) * (this.renderWidth()/this.renderHeight()) + (this.params.referenceOffset||0.0)
			
				}
			}

			// } else {
			// 	this.playbackMaterial.uniforms.tReference.value = Utils.blackTexture;
			// }
		} else {
			if (this.playbackMaterial.uniforms.tReference) this.playbackMaterial.uniforms.tReference.value = Utils.blackTexture;
		}

		if (this.playbackMaterial.uniforms.tReference && !this.confirmed && !this.confirming && !CameraController.isReady()) this.playbackMaterial.uniforms.tReference.value = Utils.blackTexture;
		// if (this.params.referenceMontage) {
		// 	var ratio = this.referenceMontage.getRatio();

		// 	this.playbackMaterial.uniforms.referenceRatio.value.set(1,1);
		// 	// if ( (1/ratio) > this.renderWidth() / this.renderHeight()) {
		// 	// 	this.playbackMaterial.uniforms.referenceRatio.value.set((ratio) / (this.renderWidth() / this.renderHeight()), 1, 1);
		// 	// } else {
		// 	// 	this.playbackMaterial.uniforms.referenceRatio.value.set(1, (1/ratio) / (this.renderHeight()/this.renderWidth()), 1);
		// 	// }

		// 	if (this.active) this.referenceMontage.update();

		// 	this.playbackMaterial.uniforms.referenceAlpha.value = this.params.referenceAlpha !== undefined ? this.params.referenceAlpha : 1.0;
		// 	this.playbackMaterial.uniforms.referenceOffset.value.set(0.0, 0.0);
		// 	this.playbackMaterial.uniforms.tReference.value = this.referenceMontage.getTexture();

		// 	if (this.params.tracking) {
		// 		this.playbackMaterial.uniforms.referenceOffset.value.x = (0.5-OpticalFlowController.getCenterPoint().x) * (this.renderWidth()/this.renderHeight());
		// 		this.playbackMaterial.uniforms.referenceOffset.value.y = 0.5-OpticalFlowController.getCenterPoint().y + (this.params.referenceOffset||0.0);
		// 	}
		// }
		if ((this.params.reference ||this.params.referenceMontage) && !this.params.referenceOnConfirm && (this.confirmed || this.confirming)) this.playbackMaterial.uniforms.referenceAlpha.value = 0.0;
		
		
		//
		// - scale plane to fill screen -
		//
		// if (this.videoWidth / this.videoHeight > this.renderWidth() / this.renderHeight()) {
		// 	this.plane.scale.set( (this.videoWidth / this.videoHeight) / (this.renderWidth() / this.renderHeight()), 1, 1 );
		// } else {
		// 	this.plane.scale.set( 1, (this.videoHeight/this.videoWidth) / (this.renderHeight()/this.renderWidth()), 1 );
		// }
		if (!this.isLiveFeed && this.cameraImg && this.cameraImg.loaded && FrameRecorder.isReady()) {
			if (!this.recording && !this.buttonCleared) {
				if (!this.recordButtonShown && !this.confirming && !this.confirmed) {
					$(this.recordButton).show();
					if (!SETTINGS.MECHANICAL_TURK_MODE) $(this.skipButton).show();
					this.recordButtonShown = true;
				}
				this.buttonCleared  = true;
				this.recordButtonContext.fillStyle = '#ffffff';
				this.recordButtonContext.drawImage(this.cameraImg, 0, 0, 202, 202);
			} else if (this.recording) {
				this.buttonCleared = false;
				this.recordButtonContext.fillStyle = '#ffffff';
				this.recordButtonContext.drawImage(this.cameraImg, 0, 0, 202, 202);

				var pc = FrameRecorder.currentFrame / FrameRecorder.numFrames;
				this.recordButtonContext.fillStyle = '#000000';
				this.recordButtonContext.beginPath();
				this.recordButtonContext.moveTo(101, 102);
				this.recordButtonContext.arc(101, 102, 78, -Math.PI*0.5, -Math.PI*0.5+Math.PI*2.0*pc);
				this.recordButtonContext.closePath();
				this.recordButtonContext.fill();
			}

			//done recording
			if (this.recording) {
				var done = FrameRecorder.update();
				if (!tracked && this.faceRenderer && !this.confirming && !this.confirmed) {
					tracked = true;
					FaceTracker.track();
					this.faceRenderer.updateLiveInfo(FaceTracker.getFaces(this.renderWidth(), this.renderHeight()));
					this.faceRenderer.update();
					if (this.facesInfo && !this.facesInfo.faces[FrameRecorder.currentFrame]) this.facesInfo.faces[FrameRecorder.currentFrame] = FaceTracker.getFaces(9, 16);
					if (done) {
						if (this.facesInfo.faces) this.facesInfo.faces[0] = this.facesInfo.faces[1];
						FrameRecorder.setTemporaryFaces(this.facesInfo);
						if (this.faceRenderer) this.faceRenderer.deactivate();
					}
				}
				if (done) {
					FrameRecorder.sendAnalysis();
					if (this.info.confirmMessage) $(this.textDiv).html(Utils.replaceGender(this.info.confirmMessage, AppStatus.SEPTEMBER_GENDER));
					this.confirming = true;
					$(this.recordButton).hide();
					$(this.skipButton).hide();
					this.recordButtonShown = false;
					this.recording = false;
					this.lockLeft = this.wasLockedLeft;
					if (this.confirmSpritesheetVideo) this.confirmSpritesheetVideo.dispose();
					this.confirmSpritesheetVideo = FrameRecorder.getTemporarySpritesheet();
					this.confirmSpritesheetVideo.stop();
					this.confirmSpritesheetVideo.play(this.contentDiv);
					this.confirmSpritesheetVideo.contentDiv = this.contentDiv;
					this.confirmSpritesheetVideo.textDiv = this.textDiv;

					$(this.confirmButton).css('visibility', 'visible');
					$(this.cancelButton).css('visibility', 'visible');
					this.confirmButtonShown = true;
					this.maxActive = -1;
					// this.recordedTextures = FrameRecorder.getTemporaryTextures();
				}
			}
		}

		//confirm state
		// window.exportSpritesheet = this.exportSpritesheet;

		var ratio = 16/9;
		var ratioInv = 9/16;
		if (this.confirmed && this.active && !this.isLiveFeed) {

			if (!this.exportSpritesheet.isReady()) this.exportSpritesheet.prepare();
			if (this.exportSpritesheet.isReady() && !this.exportSpritesheet.playing) {
				this.exportSpritesheet.stop();
				this.exportSpritesheet.play(this.contentDiv);
			}	

			this.exportSpritesheet.contentDiv = this.contentDiv;
			this.exportSpritesheet.textDiv = this.textDiv;
			this.exportSpritesheet.update();
			this.playbackMaterial.uniforms.tDiffuse.value = this.exportSpritesheet.getTexture();
			// var ratio = 16/9;
			// if (this.renderWidth()/this.renderHeight() < 9/16) { //crop sides
			// 	this.playbackMaterial.uniforms.ratio.value.set(ratio/(this.renderHeight()/this.renderWidth()), 1);
			// 	this.plane.scale.set(1,1,1);
			// } else if (this.renderWidth()/this.renderHeight() < 1) { //square-ish, crop
			// 	this.playbackMaterial.uniforms.ratio.value.set(1, (1/ratio)/(this.renderWidth()/this.renderHeight()));
			// 	this.plane.scale.set(1,1,1);
			// } else { //too wide, side bars
			// 	this.playbackMaterial.uniforms.ratio.value.set(1, 1);
			// 	this.plane.scale.set(9/16 / (this.renderWidth()/this.renderHeight()), 1, 1);
			// }
			if (this.exportSpritesheet.hasErrors()) {
				this.confirming = this.confirmed = false;
				$(this.recordButton).show();
				if (!SETTINGS.MECHANICAL_TURK_MODE) $(this.skipButton).show();
				this.recordButtonShown = true;
				this.recording = false;
				this.buttonCleared = false;
				this.lockLeft = this.wasLockedLeft;
				this.lockRight = this.wasLockedRight;
				this.exportSpritesheet.stop();
				$(this.textDiv).html(Utils.replaceGender(this.info.description?this.info.description[SETTINGS.LANGUAGE]:(this.info.text||''), AppStatus.SEPTEMBER_GENDER));
				

				this.animationStarted = true;
				this.animationPc = 0.0;
				this.animationStartTime = performance.now()+(this.params.effect ? (this.params.effect.animationDelay||0):0 )*1000;
				this.maxActive = -1;

				if (this.faceRenderer) this.faceRenderer.activate(this.contentDiv);
				$(this.confirmButton).css('visibility', 'hidden');
				$(this.cancelButton).css('visibility', 'hidden');
			}



		} else if (this.confirming && this.active) {
			if (!this.confirmSpritesheetVideo.isReady()) this.confirmSpritesheetVideo.prepare();
			if (this.confirmSpritesheetVideo.isReady() && !this.confirmSpritesheetVideo.playing) {
				this.confirmSpritesheetVideo.stop();
				this.confirmSpritesheetVideo.play(this.contentDiv);
			}

			this.confirmSpritesheetVideo.update();
			this.playbackMaterial.uniforms.tDiffuse.value = this.confirmSpritesheetVideo.getTexture();
			// var ratio = 16/9;
			// if (this.renderWidth()/this.renderHeight() < 9/16) { //crop sides
			// 	this.playbackMaterial.uniforms.ratio.value.set(ratio/(this.renderHeight()/this.renderWidth()), 1);
			// 	this.plane.scale.set(1,1,1);
			// } else if (this.renderWidth()/this.renderHeight() < 1) { //square-ish, crop
			// 	this.playbackMaterial.uniforms.ratio.value.set(1, (1/ratio)/(this.renderWidth()/this.renderHeight()));
			// 	this.plane.scale.set(1,1,1);
			// } else { //too wide, side bars
			// 	this.playbackMaterial.uniforms.ratio.value.set(1, 1);
			// 	this.plane.scale.set(9/16 / (this.renderWidth()/this.renderHeight()), 1, 1);
			// }
		} else {

			// ratio = this.videoHeight / this.videoWidth;
			// ratioInv = this.videoWidth / this.videoHeight;

		// 	if (this.params.fill === 'center') {
			
		// 	this.plane.scale.set(1, (this.videoHeight/this.videoWidth) * 1 * (this.renderWidth() / this.renderHeight()), 1.0);
		// 	this.plane.scale.multiplyScalar(1.0 - (this.params.padding==undefined?0.1:this.params.padding));
		// 	this.plane.material.uniforms.ratio.value.set(1,1);
			
		// } else if (this.params.fill === 'square') {

		// 	if (this.videoHeight > this.videoWidth) {
		// 		this.plane.material.uniforms.ratio.value.set(1, this.videoWidth/this.videoHeight);
		// 	} else {
		// 		this.plane.material.uniforms.ratio.value.set(this.videoHeight/this.videoWidth, 1);
		// 	}
		// 	if (this.renderWidth() > this.renderHeight()) {
		// 		this.plane.scale.set(1 / (this.renderWidth() / this.renderHeight()), 1, 1);
		// 	} else {
		// 		this.plane.scale.set(1, 1 / (this.renderHeight()/this.renderWidth()), 1);
		// 	}
		// 	this.plane.scale.multiplyScalar(1.0 - (this.params.padding==undefined?0.1:this.params.padding));

		// } else {

		// 	this.plane.material.uniforms.ratio.value.set(1,1);
		// 	this.plane.scale.set(1,1,1);
		// 	if (this.videoWidth / this.videoHeight > this.renderWidth() / this.renderHeight()) {
		// 		this.plane.material.uniforms.ratio.value.set( 1.0 / ((this.videoWidth / this.videoHeight) / (this.renderWidth() / this.renderHeight())), 1);
		// 		//this.plane.scale.set((this.videoWidth / this.videoHeight) / (this.renderWidth() / this.renderHeight()), 1, 1);
		// 	} else {
		// 		this.plane.material.uniforms.ratio.value.set(1, 1.0 / ((this.videoHeight/this.videoWidth) / (this.renderHeight()/this.renderWidth())) );
		// 		//this.plane.scale.set(1, (this.videoHeight/this.videoWidth) / (this.renderHeight()/this.renderWidth()), 1);
		// 	}
		// }

			// this.plane.scale.x *= CameraController.getFlip();
			this.playbackMaterial.uniforms.tDiffuse.value = CameraController.isReady() ? CameraController.texture : Utils.blackTexture;
		}



		//
		// Playback
		//
		// if (this.confirming || this.confirmed) {
			if (this.params.fill === 'center') {
			
				this.plane.scale.set(1, ratio * 1 * (this.renderWidth() / this.renderHeight()), 1.0);
				this.plane.scale.multiplyScalar(1.0 - (this.params.padding==undefined?0.1:this.params.padding));
				this.plane.material.uniforms.ratio.value.set(1,1);
				
			} else if (this.params.fill === 'square') {

				if (this.videoHeight > this.videoWidth) {
					this.plane.material.uniforms.ratio.value.set(1, ratioInv);
				} else {
					this.plane.material.uniforms.ratio.value.set(ratio, 1);
				}
				if (this.renderWidth() > this.renderHeight()) {
					this.plane.scale.set(1 / (this.renderWidth() / this.renderHeight()), 1, 1);
				} else {
					this.plane.scale.set(1, 1 / (this.renderHeight()/this.renderWidth()), 1);
				}
				this.plane.scale.multiplyScalar(1.0 - (this.params.padding==undefined?0.1:this.params.padding));

			} else {

				this.plane.material.uniforms.ratio.value.set(1,1);
				this.plane.scale.set(1,1,1);
				if (ratioInv > this.renderWidth() / this.renderHeight()) {
					this.plane.material.uniforms.ratio.value.set( 1.0 / (ratioInv / (this.renderWidth() / this.renderHeight())), 1);
					//this.plane.scale.set((this.videoWidth / this.videoHeight) / (this.renderWidth() / this.renderHeight()), 1, 1);
				} else {
					this.plane.material.uniforms.ratio.value.set(1, 1.0 / (ratio / (this.renderHeight()/this.renderWidth())) );
					//this.plane.scale.set(1, (this.videoHeight/this.videoWidth) / (this.renderHeight()/this.renderWidth()), 1);
				}
			}

			// this.plane.material.uniforms.ratio.value.set(1,1);


		// } else {




			//this.videoHeight / this.videoWidth;
			//this.videoWidth / this.videoHeight;

			// if ( ratioInv > this.renderWidth() / this.renderHeight()) {
			// 	this.plane.scale.set( 1.0 / (ratioInv / (this.renderWidth() / this.renderHeight())), 1, 1);
			// 	//this.plane.scale.set((this.videoWidth / this.videoHeight) / (this.renderWidth() / this.renderHeight()), 1, 1);
			// } else {
			// 	this.plane.scale.set(1, 1.0 / (ratio / (this.renderHeight()/this.renderWidth())), 1);
			// 	//this.plane.scale.set(1, (this.videoHeight/this.videoWidth) / (this.renderHeight()/this.renderWidth()), 1);
			// }


			//
			//  
			//
		// 	this.plane.scale.set(1,1,1);
		// 	this.plane.material.uniforms.ratio.value.set(1,1);
		// 	if (ratioInv > this.renderWidth() / this.renderHeight()) {
		// 		this.plane.material.uniforms.ratio.value.set( 1.0 / (ratioInv / (this.renderWidth() / this.renderHeight())), 1 );
		// 	} else {
		// 		this.plane.material.uniforms.ratio.value.set( 1.0, 1.0 / (ratio / (this.renderHeight()/this.renderWidth())) );
		// 	}


		// 	if (ratio > 16/9) {
		// 		// console.log("rec ratio wider");
		// 	} else if (ratio < 16/9) {
		// 		// console.log("rec ratio taller");
		// 		this.plane.scale.set(16,9,0).normalize();
		// 		this.plane.scale.z = 1.0;
		// 	}
			
		// 	this.plane.scale.x *= CameraController.getFlip();
		// }




		if (!tracked && this.faceRenderer && !this.confirming && !this.confirmed) {
			FaceTracker.track();
			this.faceRenderer.updateLiveInfo(FaceTracker.getFaces(this.renderWidth(), this.renderHeight()));
			this.faceRenderer.update();
			this.faceRenderer.hidden = !this.isReady();
		}



		//
		// motionDelay confirmed or confirming
		//
		// if (this.confirming || this.confirmed) {
		if (this.fbos && (this.confirming|| this.confirmed || needsUpdate) && (!this.lastTime || (performance.now()-this.lastTime >= 1000/24))) {

			if (this.params.effect && this.params.effect.type == "motionDelay") {
				var frameDelay = Math.ceil(Math.min(this.params.effect.delay!==undefined?this.params.effect.delay:0.2, (this.fbos.length-1.01)/24 ) * 24);
				var t = CameraController.isReady() ? CameraController.texture : Utils.blackTexture;
				if (this.confirming) {
					t = this.confirmSpritesheetVideo.getTexture();
				} else if (this.confirmed) {
					t = this.exportSpritesheet.getTexture();
				}
				this.fbos.unshift(this.fbos.splice(frameDelay,1)[0]);
			}
			Utils.renderTexture(t, this.fbos[0].texture, true);
			this.fbos[0].active = true;
			this.maxActive++;
			this.lastTime = performance.now();
		}
		// }
		if (this.params.effect && this.params.effect.type == "motionDelay") {
			this.playbackMaterial.uniforms.tDiffuse.value = this.fbos[ 0 ].texture.texture; //CameraController.texture;

			if (!this.animationStarted) {
				this.animationStarted = true;
				this.animationPc = 0.0;
				this.animationStartTime = performance.now()+(this.params.effect ? (this.params.effect.animationDelay||0):0 )*1000;
			}
			this.animationPc = !this.params.effect.animation ? 1.0 : Math.pow(Utils.cmap(performance.now(), this.animationStartTime+this.params.effect.animation*1000*0.25, this.animationStartTime+this.params.effect.animation*1000, 0.0, 1.0),2.0);
			if (isNaN(this.animationPc)) this.animationPc = 1.0;

			var t = CameraController.texture;
			if (this.confirming) {
				t = this.confirmSpritesheetVideo.getTexture();
			} else if (this.confirmed) {
				t = this.exportSpritesheet.getTexture();
			}
			if (!CameraController.isReady()) t = Utils.whiteTexture;

			//spin reveal motiondelay
			if (this.params.effect.spinMode) {

				var targetBlend = Math.pow(Utils.ccmap(OpticalFlowController.globalMotionX, 75.0, 115.0, 0.0, 1.0),2.0);
				if (targetBlend > this.playbackMaterial.uniforms.revealpc.value)
					this.playbackMaterial.uniforms.revealpc.value = this.playbackMaterial.uniforms.revealpc.value * 0.9 + 0.1 * targetBlend;
				else
					this.playbackMaterial.uniforms.revealpc.value = this.playbackMaterial.uniforms.revealpc.value * 0.9 + 0.1 * targetBlend;
			}


			//--------------
			//
			// frameOffset
			//
			//--------------
			var frameDelay = Math.min(this.params.effect.delay||0.2, (this.fbos.length-1.001)/24 ) * 24;
			frameDelay = Math.min(frameDelay, Math.max(this.maxActive,0));

			var frameOffset2 = Math.round(Utils.ccmap(this.animationPc,0.0,0.5,0.0,frameDelay/2));
			var frameOffset3 = Math.round(Utils.ccmap(this.animationPc,0.0,1.0,0.0,frameDelay));
			frameOffset3 = Math.max(frameOffset2, frameOffset3);

			this.playbackMaterial.uniforms.tDiffuse2.value = frameOffset2==0 ? t : this.fbos[ 0 ].texture.texture;
			this.playbackMaterial.uniforms.tDiffuse2.value = frameOffset2==0 ? t : this.fbos[ frameOffset2 ].texture.texture;
			this.playbackMaterial.uniforms.tDiffuse3.value = frameOffset3==0 ? t : this.fbos[ frameOffset3 ].texture.texture;
		}
		
	}

	render() {
		if (!this.active) return;
		super.render();

		//render main scene
		if (this.confirmed && this.exportSpritesheet.isReady()) {
			this.exportSpritesheet.render();

		} else if (this.confirming) {
			this.confirmSpritesheetVideo.render();
		}
		if (this.referenceMontage) this.referenceMontage.render();

		//render path for mirror effect with face
		if (this.params.effect && this.params.effect.type == "mirror" && this.params.analysis == 'faces' && !this.confirmed && !this.confirming && this.playbackMaterialSimple) {
			
			this.playbackMaterialSimple.uniforms.ratio.value.set(this.playbackMaterial.uniforms.ratio.value.x, this.playbackMaterial.uniforms.ratio.value.y);
			this.plane.material = this.playbackMaterialSimple;
			this.playbackMaterialSimple.uniforms.tDiffuse.value = CameraController.isReady() ? CameraController.texture : Utils.blackTexture;
			renderer.render(this.scene, this.camera, this.fbos[0].texture, false);
				
			if (CameraController.isReady()) {
				if (this.params.faces && this.faceRenderer.faceType === 'average' && this.faceRenderer.faceFbo) renderer.render(this.scene, this.camera, this.faceRenderer.faceFbo.texture, false);
				this.faceRenderer.render(this.fbos[0].texture);
			}
		
			this.plane.material = this.playbackMaterial;
			this.playbackMaterial.uniforms.ratio.value.set(1,1);
			this.playbackMaterial.uniforms.tDiffuse.value = this.fbos[0].texture;
			renderer.render(this.scene, this.camera, this.renderTarget, false);


		//normal render path
		} else {
			if (this.isReady()) {
				renderer.render(this.scene, this.camera, this.renderTarget, false);
				if (this.faceRenderer && !this.confirmed && !this.confirming && CameraController.isReady()) {
					if (this.params.faces && this.faceRenderer.faceType === 'average' && this.faceRenderer.faceFbo) renderer.render(this.scene,this.camera, this.faceRenderer.faceFbo.texture, false);
					this.faceRenderer.render(this.renderTarget);
				}
			}
		}
		

	}

	isReady() {
		return CameraController.isReady() && this.cameraImg && this.cameraImg.loaded && FrameRecorder.isReady() && (!this.referenceMontage || this.referenceMontage.isReady());
	}
}

export default RecordView;	
